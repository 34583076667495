.AddressField {
  width: -webkit-fill-available;
  .SearchOption {
    .Address {
      width: -webkit-fill-available;
      .Text {
        padding: 0px;
      }
    }
  }
}