.Text {
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  color: #2C2C2C;
}

.T {
  font-size: 40px;
  line-height: 52px;
}

.H1 {
  font-size: 32px;
  line-height: 48px;
}

.H2 {
  font-size: 28px;
  line-height: 40px;
}

.H3 {
  font-size: 24px;
  line-height: 32px;
}

.H4 {
  font-size: 20px;
  line-height: 24px;
}

.H5 {
  font-size: 18px;
  line-height: 24px;
}

.H6 {
  font-size: 16px;
  line-height: 22px;
}

.P {
  font-size: 14px;
  line-height: 20px;
}

.S {
  font-size: 12px;
  line-height: 14px;
}

.Regular {
  font-weight: 400;
}

.Light {
  font-weight: 300;
}

.Medium {
  font-weight: 500;
}

.Semi {
  font-weight: 600;
}

.Bold {
  font-weight: 700;
}