.Success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .Text {
    text-align: center;
  }

  .Checkmark {
    padding-top: 25px;
    width: 100px;
  }
}