*, *::before, *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial;
}

.App {
  background-color: #EEE;
  padding: 30px 20%;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.Telus {
  width: 100%;
  padding-right: 10px;
}
