.Form__field {
  display: block;

  label {
    display: block;
  }

  .ant-select, .ant-picker {
    width: 100%;
  }
}