.Section {
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px;

  &.SideSection {
    margin-top: 10px;
    padding: 15px;
  }
}